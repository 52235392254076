import React from "react";
import { inject } from "mobx-react";

import PageComponent from "./PageComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { PAGES_PAGE_TAB, PAGES_HANGTAGS_TAB, PAGES_IMAGES_TAB, PAGES_TAB, PAGE_TYPE, PAGE_COLOR_DEFAULT } from "~/configs/constants";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Hangtags from "../DeviceModule/HangtagsTab";
import { dateFormat, isEmptyOrNullStr } from '../../helpers/utils'
import Images from "../DeviceModule/ImagesTab";
import { getVarFromUrl } from "../../helpers/utils";

@inject("PageStore", "DeviceStore", "FileStore", "FontStore")
class PageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: PAGES_PAGE_TAB,
      isLoading: true,
      searchText:"",
      page: {}
    };
  }

  async componentDidMount() {
    await this.getPage();

    try {
      const { font } = this.state;
      document.getElementById("font").style.fontFamily = font.name;
    } catch{}
  }

  getPage = async () => {
    const { activeTab } = this.state;

    this.setState({
      isLoading: true,
    });
    
    const { PageStore, DeviceStore } = this.props;

    let deviceId = getVarFromUrl("deviceId");
    let device = await DeviceStore.getDeviceById(deviceId);
    const pageId = getVarFromUrl("pageId");
    const tab = getVarFromUrl("tab");

    let page = {};
    page.deviceId = deviceId;
    page.type = PAGE_TYPE.tags;
    page.order = 0;
    page.color = PAGE_COLOR_DEFAULT;
    page.tagsColor = PAGE_COLOR_DEFAULT;
    page.fontColor = PAGE_COLOR_DEFAULT;

    if (pageId !== undefined && parseInt(pageId) > 0){
      page = await PageStore.getPage(pageId);
      deviceId = page.deviceId;
    }

    if (isEmptyOrNullStr(page.daysOfWeek)) page.daysOfWeek = "[]";
    if (isEmptyOrNullStr(page.startDate)) page.startDate = dateFormat(new Date());
    if (isEmptyOrNullStr(page.endDate)) page.endDate = dateFormat(new Date());

    let layouts = await this.getLayouts("", page, device);   
    let layout = await this.getLayout(layouts, page);
    
    let fonts = await this.getFonts("");   
    let font = await this.getFont(fonts, page);    

    this.setState({
      activeTab: (tab !== undefined  && tab !== null ? tab : activeTab),
      page,
      device,
      layouts,
      layout,
      fonts,
      font,
      isLoading: false
    });
  };

  getLayouts = async (filter, page, device) => {
    const { FileStore } = this.props
    let layouts = [];
    let layoutsData = await FileStore.getDesigns(filter, device.orientation);
    let hasDesign = (page.design !== undefined && page.design !== null && page.design.id > 0);
    let designId = 0;
    if (hasDesign) designId = page.design.id;
    let designFound = false;
    for (let i = 0; i < layoutsData.length; i++) {
      if (layoutsData[i].id === designId) designFound = true;
      layouts.push(
      {
        id: layoutsData[i].id,
        url: layoutsData[i].url,
        name: layoutsData[i].name.replace(".jpg",""),
        label: layoutsData[i].name.replace(".jpg","")
      });
    }

    if (hasDesign && !designFound)
      layouts.push(
      {
        id: page.design.id,
        url: page.design.url,
        name: page.design.name.replace(".jpg",""),
        label: page.design.name.replace(".jpg","")
      });

    return layouts;   
  };

  getLayout = async (layouts, page) => {
    let layout = {};
    if (layouts !== undefined && layouts !== null && layouts.length > 0) layout = layouts[0];
    for (var i = 0; i < layouts.length; i++){
      if (layouts[i].url === page.layoutLinkUrl){
        layout = layouts[i];
        break;
      }
    }; 
    return layout;
  };

  getFonts = async (filter) => {
    const { FontStore } = this.props
    let fonts = [];
    let fontsData = await FontStore.getFonts(filter);
    for(let i = 0; i < fontsData.length; i++) {
      this.cssFontFaceMidia(fontsData[i].name, fontsData[i].url);
      fonts.push(
        {
          id: fontsData[i].fontId,          
          name: fontsData[i].name,
          label: fontsData[i].name,
          cssName: fontsData[i].cssName,
          url: fontsData[i].url
        }
      );
    }
    return fonts;   
  };

  cssFontFaceMidia = (name, url) => {
    var newStyle = document.createElement('style');
    newStyle.appendChild(document.createTextNode(`\
    @font-face {\
      font-family: '${name}';\
      src: url('${url}');\
    }\
    `));
    document.head.appendChild(newStyle);    
  };

  getFont = async (fonts, page) => {
    let font = {};
    if (fonts !== undefined && fonts !== null && fonts.length > 0) font = fonts[0];
    for (var i = 0; i < fonts.length; i++){
      if (fonts[i].id === page.fontId){
        font = fonts[i];
        break;
      }
    }; 
    return font;
  };

  goDevice = () => {
    const { page } = this.state;
    const { history } = this.props;    
    history.push(`device?deviceId=${page.deviceId}&tab=${PAGES_TAB}`);
  };

  goPage = () => {
    const { page } = this.state;
    const { history } = this.props;
    history.push(`page?deviceId=${getVarFromUrl("deviceId")}&pageId=${page.pageId}&tab=${PAGES_PAGE_TAB}`);
  };

  maintainPage = async () => {
    this.setState({
      isLoading: true
    });

    const { page } = this.state;
    const { PageStore } = this.props;
   
    const result = await PageStore.update(page);
    if (result && result?.pageId > 0){
      this.setState({
        page: result
      });
      AlertComponent.success("Página salva com sucesso");
      PageStore.setPageId(result?.pageId);
      this.goPage();
    } else {
      AlertComponent.error("Falha ao salvar página");
    }    
    this.setState({
      isLoading: false
    });
  };

  onChange = (e) => {
    const { page } = this.state;
   
    const updatedPage = {
      ...page,
      [e.target.name]: e.target.value
    } 
    
    this.setState({
      page: updatedPage,
    });
  };

  onChangeLayout = (layoutItem) => {
      const { page, layout } = this.state; 
      if (layoutItem === null || layoutItem === undefined) layoutItem = layout;

      page.layoutLinkUrl = layoutItem.url;
      this.setState({
        layout: layoutItem,
        page: page
      });        
  };

  onKeyUpLayout = async () => {
    try {
      this.setState({
        layoutIsLoading: true,
      });
      const { device } = this.state; 
      const { page } = this.state;
      let filter = document.getElementById("layout").value;
      let layouts = await this.getLayouts(filter, page, device);
      this.setState({
        layouts,
      });
      this.setState({
        layoutIsLoading: false,
      });
    }
    catch {}
  };

  onChangeColor = (color) => {
    const { page } = this.state;  
    page.color = color.hex;
    this.setState({
      page: page,
    });
  };

  onChangeTagsColor = (color) => {
    const { page } = this.state;  
    page.tagsColor = color.hex;
    this.setState({
      page: page,
    });
  };
  
  onChangeFontColor = (color) => {
    const { page } = this.state;  
    page.fontColor = color.hex;
    this.setState({
      page: page,
    });
  };

  onChangeFont = (fontItem) => {
    try {
      const { page } = this.state;
      page.fontId = fontItem.id;
      document.getElementById("font").style.fontFamily = fontItem.name;
      this.setState({
        font: fontItem,
        page: page
      });       
    } catch {}
  };

  onKeyUpFont = async () => {
    try {
      this.setState({
        fontIsLoading: true,
      });   
      let filter = document.getElementById("font").value;
      let fonts = await this.getFonts(filter);
      this.setState({
        fonts,
      });
      this.setState({
        fontIsLoading: false,
      });
    }
    catch {}
  }; 

  onChangePeriod = (value,) => {
    const { page } = this.state;
    page.startDate = dateFormat(value[0]);
    page.endDate = dateFormat(value[1]);
    this.setState({
      page: page
    });
  };

  onChangeDaysOfWeek = (event, value) => {
    const { page } = this.state;
    page.daysOfWeek = JSON.stringify(value);
    this.setState({
      page: page
    });
  } 

  tabPageToggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab === tab) return;
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { isLoading, page, layouts, layout, activeTab, layoutIsLoading, fontIsLoading, font, fonts } = this.state;
    const { history } = this.props;
    const canDelete = page?.pageId > 0;
    return (
      <>
      <div className="content content-xs" style={{overflow: "hidden"}}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === PAGES_PAGE_TAB })}
              onClick={() => {
                this.tabPageToggle(PAGES_PAGE_TAB);
              }}
            >
              Página
            </NavLink>
          </NavItem>
          {(canDelete && (<>
            {page.type !== PAGE_TYPE.midia ?
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === PAGES_HANGTAGS_TAB })}
                  onClick={() => {
                    this.tabPageToggle(PAGES_HANGTAGS_TAB);
                  }}
                >
                  Etiquetas
                </NavLink>
              </NavItem>
            :
            (            
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === PAGES_IMAGES_TAB })}
                  onClick={() => {
                    this.tabPageToggle(PAGES_IMAGES_TAB);
                  }}
                >
                  Mídias
                </NavLink>
              </NavItem>
            )}
          </>
          ))}            
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={PAGES_PAGE_TAB}>
            {(isLoading && (
              <>
                <LoadingComponent />
              </>
            )) || (
                <PageComponent
                  page={page}
                  onClick={this.maintainPage}
                  onCancel={this.goDevice}
                  onChange={this.onChange}
                  layout={layout}
                  layouts={layouts}
                  onChangeLayout={this.onChangeLayout}
                  layoutIsLoading={layoutIsLoading}
                  onKeyUpLayout={this.onKeyUpLayout}
                  onChangeColor={this.onChangeColor}
                  onChangeTagsColor={this.onChangeTagsColor}
                  onChangeFontColor={this.onChangeFontColor}
                  fontIsLoading={fontIsLoading}
                  onKeyUpFont={this.onKeyUpFont}
                  onChangeFont={this.onChangeFont}
                  fonts={fonts}
                  font={font}
                  calendarMinDate={new Date()}
                  onChangePeriod={this.onChangePeriod}
                  onChangeDaysOfWeek={this.onChangeDaysOfWeek}
                />
            )}
          </TabPane>
          <TabPane tabId={PAGES_HANGTAGS_TAB}>
            <Hangtags history={history} />
          </TabPane> 
          <TabPane tabId={PAGES_IMAGES_TAB}>
              <Images history={history} />
          </TabPane>             
        </TabContent>
      </div> 
      </>
    );
  }
}

export default PageContainer;