import { observable, action } from "mobx";
import deviceService from "../services/DeviceService";

export class DeviceStore {
  @observable devices = [];
  @observable deviceId = 0;

  @action.bound
  getDevices = async (organizationId, skip) => {
    this.devices = [];
    const data = await deviceService.getAll(organizationId, skip);
    if (data === null || data === "" || data === undefined) return null;
    this.devices = data.devices;
    return data;
  };

  @action.bound
  assign = async (deviceId, activationCode) => {
    if (deviceId === undefined || deviceId < 1 || activationCode === undefined || activationCode === "")
      return false;
    
    const data = await deviceService.assign(deviceId, activationCode);
    return data
  };

  @action.bound
  validateDevice = async () => {
    if (!this.deviceId || parseInt(this.deviceId) < 1){
      return false;
    }
    return true;
  };

  @action.bound
  getDevice = async () => {
    const data = await deviceService.getById(this.deviceId);
    return data ?? {};
  }

  @action.bound
  getDeviceById = async (deviceId) => {
    const data = await deviceService.getById(deviceId);
    return data ?? {};
  }

  @action.bound
  setDeviceId = (deviceId) => {
    this.deviceId = parseInt(deviceId);
  }

  @action.bound
  getDeviceId = () => {
    return this.deviceId;
  }

  @action.bound
  update = async (device) => {
    const data = await deviceService.update(device);
    return data;
  }

  @action.bound
  insertDevice = async (device) => {
    const data = await deviceService.insert(device);
    return data;
  }

  @action.bound
  logout = async () => {
    this.devices = [];
    this.deviceId = 0;  
  }

  @action.bound
  delete = async (deviceId) => {
    const data = await deviceService.delete(deviceId);
    return data;
  }
}

const deviceStore = new DeviceStore();
export default deviceStore;