import React from "react";
import { inject } from "mobx-react";
import {
  Button,
  Row,
  Col,
  FormGroup
} from "reactstrap";

import ImportationsComponent from "./ImportationsComponent";
import LoadingComponent from "~/components/LoadingComponent";
import AlertComponent from "~/components/AlertComponent";
import { USER_LEVEL } from "../../configs/constants";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteSx } from '../../helpers/utils'
import PaginationComponent from "~/components/PaginationComponent";

@inject("WsStore", "ProductStore", "UserStore", "OrganizationStore", "FileStore")
class ImportationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      files: [],
      selectedFile: null
    };
  }

  async componentDidMount() {
    await this.getFiles();
  }

  onFileChange = async event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  getFiles = async (organization, organizationName, skip) => {
    this.setState({
      isLoading: true,
    });
    if (skip === undefined) skip = 0; 
    const { FileStore } = this.props;
    let { organizations } = this.state;
    if (organization === undefined) {
      organizations = await this.getOrganizations(organizationName);
      organization = organizations[0];
    }  
    
    const data = await FileStore.getByOrganizationId(organization.organizationId, skip);
    let files = [];
    let countItems = 0;
    let currentPage = 1;
    let countPages = 1;
    if (data && data !== "" && data.files.length > 0) {
      files = data.files;
      countItems = data.countItems;
      countPages = data.countPages;
    }  
    this.setState({
      organization: organization,
      organizations: organizations,
      files: files,
      currentPage: currentPage,
      countItems: countItems,
      countPages: countPages,
      skip: skip,
      isLoading: false,
    });
  };

  getOrganizations = async (value) => {
    const { OrganizationStore } = this.props;
    let organizationsData = await OrganizationStore.getOrganizations(value);
    return organizationsData.organizations;
  };

  onReceivedAgent = async () => {
    const { organization, skip } = this.state;

    this.setState({
      isLoading: true,
    });

    const { WsStore } = this.props;
    var isOk = await WsStore.send();
    if (isOk) AlertComponent.success("Agente notificado sucesso");
    else AlertComponent.error("Falha ao notificar agente");

    this.setState({
      isLoading: false
    });

    await this.getFiles(organization, organization.name, skip);
  };

  onFileUpload = async () => {
    const { selectedFile, organization, skip } = this.state;
    if (selectedFile == null) AlertComponent.error("Nenhum arquivo selecionado");
    else if (organization === null || organization === undefined || organization.organizationId === 0) AlertComponent.error("Estabelecimento inválido");
    else {
      this.setState({
        isLoading: true,
      });      
      const formData = new FormData();
      formData.append(selectedFile.name, selectedFile);
  
      const { ProductStore } = this.props;
      const uploadIsOk = await ProductStore.uploadFile(formData, organization.organizationId);
  
      if (uploadIsOk) AlertComponent.success("Arquivo enviado para processamento");
      else AlertComponent.error("Falha ao importar arquivo");
 
      this.setState({
        isLoading: false,
        selectedFile: null
      });

      await this.getFiles(organization, organization.name, skip);
    }   
  };

  onKeyUpOrganization = async (value) => {
    try {
      let organizations = await this.getOrganizations(value);
      this.setState({
        organizations
      });
    }
    catch {}
  };

  onChangeOrganization = async (organizationName) => {
    let { organizations, organization } = this.state;
    for(let i = 0; i < organizations.length; i++){
      if (organizations[i].name === organizationName){
        organization = organizations[i];
        break;
      }
    }
    this.setState({
      organization: organization
    });

    await this.getFiles(organization, organization.name);
  };
  
  goRefresh = async () => {
    const { organization, skip } = this.state;
    await this.getFiles(organization, organization.name, skip);
  };
  
  onPageChange = async (currentPage) => {  
    this.setState({
      isLoading: true
    });  
    const skip = (currentPage - 1) * 100;
    const { organization } = this.state;
    await this.getFiles(organization, organization.name, skip);
    this.setState({
      currentPage: currentPage,
      skip: skip,
      isLoading: false
    });
  };

  render() {
    const { isLoading, files, organization, organizations, currentPage, countItems } = this.state;
    const { UserStore } = this.props;
    return (
      <>
        {(isLoading && (
            <LoadingComponent />
        )) || (
          <>
            <div className="content content-xs">
              <Row>
                {UserStore.getLevel() === USER_LEVEL.manager ?
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <label style={{marginBottom: "0px"}}>Estabelecimento:</label>
                      <Autocomplete
                        noOptionsText={"Nenhum item"}
                        onKeyUp={(event, value) =>   
                          this.onKeyUpOrganization(document.getElementById("organization").value)
                        }                          
                        onChange={(event, value) =>                         
                          this.onChangeOrganization(value)
                        }
                        style={{paddingBottom: "10px"}}                                      
                        value={organization?.name}
                        id="organization"
                        options={organizations.map((organization) => organization.name)}
                        isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                        size="small"
                        renderInput={(params) => (
                          <TextField  {...params} variant='standard'/>
                        )}
                        sx={autoCompleteSx}
                        renderOption={(props, option) => {
                          return (
                            <span  {...props}>
                              {option}
                            </span>
                          );
                        }}                      
                      />
                    </FormGroup>
                  </Col>
                : (<></>)} 
                <Col xs={12} md={8} >
                  <input type="file" onChange={this.onFileChange} />
                  <Button 
                    style={{marginLeft: "10px"}}
                    className="btn-fill btn-xs btn-xs-0"
                    color="secondary"
                    onClick={()=>this.onFileUpload()}>
                    Enviar arquivo
                  </Button>   
                  <Button 
                    style={{marginLeft: "10px"}}
                    className="btn-fill btn-xs btn-xs-0"
                    color="secondary"
                    onClick={()=>this.onReceivedAgent()}>
                    Receber arquivo do agente
                  </Button>
                </Col>                
                <Col xs={12} md={4} style={{textAlign: "right"}}>
                  <Button
                    className="btn-fill btn-xs"
                    color="primary"
                    type="submit"
                    onClick={()=>this.goRefresh()}
                  >
                    Atualizar lista   
                  </Button>
                </Col>                
              </Row>
              <br/>
              <Row>
                <Col xs={3} md={3}>
                  Início
                </Col>
                <Col xs={3} md={3}>
                  Atualização
                </Col>
                <Col xs={3} md={3}>
                  Status
                </Col>
                <Col xs={3} md={3}>
                  Linhas
                </Col>
              </Row>  
              {(files && (            
                <ImportationsComponent
                  files={files}
                />)
              )}
              <PaginationComponent
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={countItems}
                onPageChange={this.onPageChange}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default ImportationsContainer;
